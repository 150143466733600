<template>
  <b-card
    no-body
    class="important-indicator"
  >
    <b-card-header>
      <b-card-title>
        {{ $t('Important stats') }}
        <label class="sub-title">
          <span v-if="!rangeDate.from && !rangeDate.to">( {{ $t('Select date range to filter') }} )</span>
          <span v-else>( {{ $t('From') }} <strong>{{ rangeDate.from }}</strong> {{ $t('to') }} <strong>{{ rangeDate.to }}</strong> )</span>
          <flat-picker
            v-model="rangeDate.string"
            :config="dateRangeConfig"
            @on-close="onDatePicker"
          />
        </label>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <div class="d-grid">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="item"
        >
          <div class="text">
            {{ $t(item.text) }}
            <feather-icon
              v-b-tooltip.hover
              icon="HelpCircleIcon"
              size="18"
              class="text-muted cursor-pointer"
              :title="$t(item.description)"
              variant="gradient-primary"
            />
          </div>
          <div
            v-if="item.is_price"
            class="price"
          >
            {{ item.value }}
          </div>
          <div
            v-else-if="item.is_rate"
            class="price"
          >
            {{ item.value }}%
          </div>
          <div
            v-else
            class="price"
          >
            {{ item.value }}
          </div>
          <div
            v-if="pathday"
            class="sub"
          >
            Trong {{ pathday }} ngày
            <!-- {{ item.percent }}% -->
            <!-- <feather-icon
              v-if="item.percent >= 0"
              icon="ChevronUpIcon"
              size="24"
              class="text-success"
            />
            <feather-icon
              v-else
              icon="ChevronDownIcon"
              size="24"
              class="text-danger"
            /> -->
          </div>
          <div
            v-else
            class="sub"
          >
            Trong tất cả các ngày
            <!-- {{ item.percent }}% -->
            <!-- <feather-icon
              v-if="item.percent >= 0"
              icon="ChevronUpIcon"
              size="24"
              class="text-success"
            />
            <feather-icon
              v-else
              icon="ChevronDownIcon"
              size="24"
              class="text-danger"
            /> -->
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  VBTooltip,
} from 'bootstrap-vue'
import flatPicker from 'vue-flatpickr-component'
import general from '@/mixins/index'

export default {
  components: {
    flatPicker,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [general],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    pathday: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      textDay: 'in the last 7 days',
      rangeDate: {
        string: null,
        from: null,
        to: null,
      },
      dateRangeConfig: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        maxDate: 'today',
      },
    }
  },
  methods: {
    onDatePicker(selectedDates, dateStr) {
      if (dateStr.split(' ').length !== 1) {
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.from = dateStr.split(' ')[0]
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.to = dateStr.split(' ')[2]
        this.$emit('on-date-change', this.rangeDate.from, this.rangeDate.to)
      }
    },
  },
}
</script>

<style lang="sass">
.important-indicator
  .sub-title
    font-size: 12px
    color: #a6a4b0
  .item
    border-right: 1px solid #e5e5e5
    padding-top: 5px
    padding-bottom: 5px
    margin-right: 30px

    &:last-child
      border: none

    .price
      margin-top: 5px
      margin-bottom: 5px
      font-weight: bold
      font-size: 20px

  .d-grid
    display: grid
    grid-template-columns: auto auto auto auto

  .flatpickr-input
    visibility: hidden

  .sub-title
    cursor: pointer
</style>
